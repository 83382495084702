import { initializeApp, type FirebaseOptions } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  initializeFirestore,
  type FirestoreSettings
} from 'firebase/firestore';

// Ensure environment variables are defined
const requiredEnvVars = [
  'VITE_FIREBASE_API_KEY',
  'VITE_FIREBASE_AUTH_DOMAIN',
  'VITE_FIREBASE_PROJECT_ID',
  'VITE_FIREBASE_STORAGE_BUCKET',
  'VITE_FIREBASE_MESSAGING_SENDER_ID',
  'VITE_FIREBASE_APP_ID',
  'VITE_FIREBASE_MEASUREMENT_ID',
  'VITE_ADMIN_EMAIL'
] as const;

// Check environment variables before initialization
for (const varName of requiredEnvVars) {
  if (!import.meta.env[varName]) {
    throw new Error(`Missing required environment variable: ${varName}`);
  }
}

// Initialize Firebase with required configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
} as const;

// Only add analytics in production
if (!import.meta.env.DEV) {
  Object.assign(firebaseConfig, {
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  });
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Firestore with minimal settings
const settings: FirestoreSettings = {
  experimentalForceLongPolling: true
};

export const db = initializeFirestore(app, settings);

// Initialize admin email
export const ADMIN_EMAIL = import.meta.env.VITE_ADMIN_EMAIL;