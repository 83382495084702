import { create } from 'zustand';
import { 
  collection, 
  query, 
  getDocs, 
  updateDoc,
  doc,
  orderBy,
  Timestamp,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface Report {
  id: string;
  agentId: string;
  agentName: string;
  agentRole: string;
  message: string;
  status: 'pending' | 'resolved' | 'rejected';
  createdAt: Date;
  resolvedAt?: Date;
  resolvedBy?: string;
  resolution?: string;
  adminNotes?: string;
  lastEditedAt?: Date;
  lastEditedBy?: string;
}

interface ReportsState {
  reports: Report[];
  loading: boolean;
  error: string | null;
  fetchReports: () => Promise<void>;
  updateReportStatus: (reportId: string, status: Report['status'], resolution?: string) => Promise<void>;
  editReport: (reportId: string, data: Partial<Report>) => Promise<void>;
}

export const useReportsStore = create<ReportsState>((set, get) => ({
  reports: [],
  loading: false,
  error: null,

  fetchReports: async () => {
    set({ loading: true, error: null });
    try {
      const reportsRef = collection(db, 'reports');
      const q = query(reportsRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      
      const reports = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: (doc.data().createdAt as Timestamp).toDate(),
        resolvedAt: doc.data().resolvedAt ? (doc.data().resolvedAt as Timestamp).toDate() : undefined,
        lastEditedAt: doc.data().lastEditedAt ? (doc.data().lastEditedAt as Timestamp).toDate() : undefined
      })) as Report[];
      
      set({ reports, loading: false });
    } catch (error) {
      console.error('Error fetching reports:', error);
      set({ error: 'Failed to fetch reports', loading: false });
    }
  },

  updateReportStatus: async (reportId, status, resolution) => {
    set({ loading: true, error: null });
    try {
      const reportRef = doc(db, 'reports', reportId);
      const updateData: any = {
        status,
        resolvedAt: status !== 'pending' ? serverTimestamp() : null,
        resolvedBy: status !== 'pending' ? 'Admin' : null,
        lastEditedAt: serverTimestamp(),
        lastEditedBy: 'Admin'
      };
      
      if (resolution) {
        updateData.resolution = resolution;
      }
      
      await updateDoc(reportRef, updateData);
      await get().fetchReports();
      set({ loading: false });
    } catch (error) {
      console.error('Error updating report:', error);
      set({ error: 'Failed to update report', loading: false });
    }
  },

  editReport: async (reportId, data) => {
    set({ loading: true, error: null });
    try {
      const reportRef = doc(db, 'reports', reportId);
      const updateData = {
        ...data,
        lastEditedAt: serverTimestamp(),
        lastEditedBy: 'Admin'
      };
      
      await updateDoc(reportRef, updateData);
      await get().fetchReports();
      set({ loading: false });
    } catch (error) {
      console.error('Error editing report:', error);
      set({ error: 'Failed to edit report', loading: false });
      throw error;
    }
  },
}));
