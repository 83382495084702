import { create } from 'zustand';
import { 
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../lib/firebase';

interface Notice {
  id: string;
  content: string;
  createdAt: Date;
}

interface NoticeStore {
  addNotice: (content: string) => Promise<void>;
  updateNotice: (id: string, content: string) => Promise<void>;
  deleteNotice: (id: string) => Promise<void>;
}

export const useNoticeStore = create<NoticeStore>((set) => ({
  addNotice: async (content: string) => {
    try {
      await addDoc(collection(db, 'notices'), {
        content,
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error adding notice:', error);
      throw error;
    }
  },

  updateNotice: async (id: string, content: string) => {
    try {
      const noticeRef = doc(db, 'notices', id);
      await updateDoc(noticeRef, {
        content,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error updating notice:', error);
      throw error;
    }
  },

  deleteNotice: async (id: string) => {
    try {
      const noticeRef = doc(db, 'notices', id);
      await deleteDoc(noticeRef);
    } catch (error) {
      console.error('Error deleting notice:', error);
      throw error;
    }
  },
}));