import { Navbar } from '../components/Navbar';
import { NoticeBar } from '../components/NoticeBar';
import { HeroBanner } from '../components/HeroBanner';
import { QuickLinks } from '../components/QuickLinks';
import { RulesSection } from '../components/RulesSection';
import { Footer } from '../components/Footer';

export function LandingPage() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <NoticeBar />
      <HeroBanner />
      <QuickLinks />
      <RulesSection />
      <Footer />
    </div>
  );
}