// Define console method types
type ConsoleMethod = keyof Console;

// Ensure console methods exist
if (typeof window !== 'undefined') {
  const methods: ConsoleMethod[] = ['debug', 'info', 'log', 'warn', 'error'];
  
  methods.forEach((method) => {
    if (!(method in console)) {
      (console as any)[method] = console.log || (() => {});
    }
  });
}
