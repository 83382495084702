import { useState } from 'react';
import { Agent } from '../../types/agent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faEye } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { AgentModal } from './AgentModal';
import { ReportModal } from './ReportModal';

interface QuickAgentTableProps {
  agents: Agent[];
}

export function QuickAgentTable({ agents }: QuickAgentTableProps) {
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [reportingAgent, setReportingAgent] = useState<Agent | null>(null);

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-yellow-500/20">
          <thead className="bg-gray-900 sticky top-0 z-10">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-yellow-500 uppercase tracking-wider">
                Name / ID
              </th>
              <th className="px-4 py-3 text-center text-xs font-medium text-yellow-500 uppercase tracking-wider">
                Social
              </th>
              <th className="px-4 py-3 text-center text-xs font-medium text-yellow-500 uppercase tracking-wider w-24">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-yellow-500/20">
            {agents.map((agent) => (
              <tr key={agent.id} className="hover:bg-yellow-500/5">
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="h-8 w-8 rounded-full bg-yellow-500/10 flex items-center justify-center mr-3">
                      <span className="text-yellow-500 text-sm font-medium">
                        {agent.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-yellow-500">{agent.name}</div>
                      <div className="text-xs text-gray-400">{agent.magicId}</div>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="flex items-center justify-center space-x-4">
                    {agent.whatsapp && (
                      <a
                        href={`https://wa.me/${agent.whatsapp}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-500 hover:text-green-400 transition-colors"
                        title="WhatsApp"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                      </a>
                    )}
                    {agent.messenger && (
                      <a
                        href={agent.messenger.startsWith('http') ? agent.messenger : `https://m.me/${agent.messenger}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-400 transition-colors"
                        title="Messenger"
                      >
                        <FontAwesomeIcon icon={faFacebookMessenger} size="lg" />
                      </a>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="flex items-center justify-center space-x-3">
                    <button
                      onClick={() => setSelectedAgent(agent)}
                      className="text-yellow-500 hover:text-yellow-400 transition-colors"
                      title="View Details"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button
                      onClick={() => setReportingAgent(agent)}
                      className="text-red-500 hover:text-red-400 transition-colors"
                      title="Report Agent"
                    >
                      <FontAwesomeIcon icon={faFlag} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {agents.length === 0 && (
          <div className="text-center py-4 text-gray-400">
            No master agents available
          </div>
        )}
      </div>

      {selectedAgent && (
        <AgentModal
          agent={selectedAgent}
          onClose={() => setSelectedAgent(null)}
          onReport={setReportingAgent}
        />
      )}

      {reportingAgent && (
        <ReportModal
          agent={reportingAgent}
          onClose={() => setReportingAgent(null)}
        />
      )}
    </>
  );
}