import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faUserLock, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

export function RulesSection() {
  return (
    <section className="py-12 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Agent Rules */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="bg-gray-900 rounded-lg shadow-lg overflow-hidden border border-yellow-500/20"
          >
            <div className="p-4 border-b border-yellow-500/20">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faShieldAlt} className="text-yellow-500 text-xl mr-3" />
                <h3 className="text-lg font-semibold text-yellow-500">Agent Rules</h3>
              </div>
            </div>
            <div className="p-6">
              <ul className="space-y-4">
                {[
                  'Maintain professional conduct at all times',
                  'Provide accurate information to players',
                  'Follow the hierarchical structure',
                  'Report any suspicious activities',
                  'Keep player information confidential'
                ].map((rule, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3, delay: 0.1 * index }}
                    className="flex items-start text-gray-400"
                  >
                    <FontAwesomeIcon 
                      icon={faCheckCircle} 
                      className="text-yellow-500 mt-1 mr-3 flex-shrink-0" 
                    />
                    <span>{rule}</span>
                  </motion.li>
                ))}
              </ul>
            </div>
          </motion.div>

          {/* Account Rules */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="bg-gray-900 rounded-lg shadow-lg overflow-hidden border border-yellow-500/20"
          >
            <div className="p-4 border-b border-yellow-500/20">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faUserLock} className="text-yellow-500 text-xl mr-3" />
                <h3 className="text-lg font-semibold text-yellow-500">Account Rules</h3>
              </div>
            </div>
            <div className="p-6">
              <ul className="space-y-4">
                {[
                  'One account per user only',
                  'Keep login credentials secure',
                  'Regular password updates required',
                  'No sharing of account information',
                  'Report unauthorized access immediately'
                ].map((rule, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3, delay: 0.1 * index }}
                    className="flex items-start text-gray-400"
                  >
                    <FontAwesomeIcon 
                      icon={faCheckCircle} 
                      className="text-yellow-500 mt-1 mr-3 flex-shrink-0" 
                    />
                    <span>{rule}</span>
                  </motion.li>
                ))}
              </ul>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}