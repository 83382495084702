import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const bannerImages = [
  'https://thakurbook16.xyz:5012/banner-uploads/txnrep-2121000022955.jpg',
  'https://i.postimg.cc/k5bgRb7d/banner1.jpg',
  'https://images.unsplash.com/photo-1518895949257-7621c3c786d7?w=1600&h=500&fit=crop'
];

export function HeroBanner() {
  return (
    <div className="relative">
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        effect="fade"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination, EffectFade]}
        className="h-[160px] sm:h-[180px] md:h-[280px] lg:h-[400px] relative !overflow-hidden"
      >
        {bannerImages.map((image, index) => (
          <SwiperSlide key={index}>
            <div
              className="w-full h-full bg-cover bg-center transition-transform duration-300"
              style={{ backgroundImage: `url(${image})` }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
