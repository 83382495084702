import { create } from 'zustand';
import { auth, ADMIN_EMAIL } from '../lib/firebase';
import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  User
} from 'firebase/auth';
import toast from 'react-hot-toast';
import { persist } from 'zustand/middleware';

interface AuthState {
  user: User | null;
  loading: boolean;
  isAdmin: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      loading: true,
      isAdmin: false,

      signIn: async (email, password) => {
        if (email !== ADMIN_EMAIL) {
          throw new Error('Unauthorized access');
        }

        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          set({ 
            user: userCredential.user,
            isAdmin: userCredential.user.email === ADMIN_EMAIL,
            loading: false
          });
        } catch (error: any) {
          console.error('Auth error:', error);
          throw new Error(error.message);
        }
      },

      signOut: async () => {
        try {
          await firebaseSignOut(auth);
          set({ user: null, isAdmin: false, loading: false });
        } catch (error) {
          console.error('Sign out error:', error);
          throw error;
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        user: state.user,
        isAdmin: state.isAdmin
      })
    }
  )
);

// Setup auth listener
onAuthStateChanged(auth, (user) => {
  useAuthStore.setState({ 
    user, 
    loading: false,
    isAdmin: user?.email === ADMIN_EMAIL
  });
});