import { useEffect, useState } from 'react';
import { Agent } from '../../types/agent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faStar, 
  faXmark, 
  faUser, 
  faIdCard, 
  faUserShield,
  faUserTie,
  faCalendarAlt,
  faFlag
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface AgentModalProps {
  agent: Agent;
  onClose: () => void;
  onReport?: (agent: Agent) => void;
}

export function AgentModal({ agent, onClose, onReport }: AgentModalProps) {
  const [upline, setUpline] = useState<Agent | null>(null);

  useEffect(() => {
    const fetchUpline = async () => {
      if (agent.uplineId) {
        try {
          const uplineDoc = await getDoc(doc(db, 'agents', agent.uplineId));
          if (uplineDoc.exists()) {
            setUpline({ id: uplineDoc.id, ...uplineDoc.data() } as Agent);
          }
        } catch (error) {
          console.error('Error fetching upline:', error);
        }
      }
    };

    fetchUpline();
  }, [agent.uplineId]);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-[#1f2231] rounded-lg max-w-md w-full border border-[#2e3348] shadow-xl">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-[#2e3348]">
          <div className="flex items-center space-x-3">
            <div className="w-10 h-10 rounded-lg bg-[#DC2626] flex items-center justify-center">
              <FontAwesomeIcon icon={faUser} className="text-white text-lg" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white">Agent Details</h3>
              <p className="text-sm text-gray-400">View complete information</p>
            </div>
          </div>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white hover:bg-[#DC2626] p-2 rounded-md transition-colors"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        
        {/* Content */}
        <div className="p-6 space-y-6">
          {/* Basic Info */}
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faUser} className="text-[#DC2626] w-5" />
              <div>
                <label className="text-sm text-gray-400">Name</label>
                <p className="text-white font-medium">{agent.name}</p>
              </div>
            </div>
            
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faIdCard} className="text-[#DC2626] w-5" />
              <div>
                <label className="text-sm text-gray-400">Magic ID</label>
                <p className="text-white font-medium">{agent.magicId}</p>
              </div>
            </div>
            
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faUserShield} className="text-[#DC2626] w-5" />
              <div>
                <label className="text-sm text-gray-400">Role</label>
                <p className="text-white font-medium">
                  {agent.role.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}
                </p>
              </div>
            </div>

            {upline && (
              <div className="flex items-center space-x-3">
                <FontAwesomeIcon icon={faUserTie} className="text-[#DC2626] w-5" />
                <div>
                  <label className="text-sm text-gray-400">Upline</label>
                  <p className="text-white font-medium">
                    {upline.name} <span className="text-gray-400">({upline.magicId})</span>
                  </p>
                  <p className="text-sm text-gray-400">
                    {upline.role.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}
                  </p>
                </div>
              </div>
            )}

            <div className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faCalendarAlt} className="text-[#DC2626] w-5" />
              <div>
                <label className="text-sm text-gray-400">Member Since</label>
                <p className="text-white font-medium">{formatDate(agent.createdAt)}</p>
              </div>
            </div>
          </div>

          {/* Rating */}
          <div className="border-t border-[#2e3348] pt-4">
            <label className="text-sm text-gray-400 block mb-2">Rating</label>
            <div className="flex space-x-1">
              {[...Array(5)].map((_, i) => (
                <FontAwesomeIcon
                  key={i}
                  icon={faStar}
                  className={i < agent.rating ? 'text-yellow-500' : 'text-gray-600'}
                />
              ))}
            </div>
          </div>
          
          {/* Contact */}
          <div className="border-t border-[#2e3348] pt-4">
            <label className="text-sm text-gray-400 block mb-3">Contact Methods</label>
            <div className="grid grid-cols-2 gap-3">
              {agent.whatsapp && (
                <a
                  href={`https://wa.me/${agent.whatsapp}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center space-x-2 bg-green-600/20 text-green-500 hover:bg-green-600/30 px-4 py-2 rounded-lg transition-colors"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                  <span>WhatsApp</span>
                </a>
              )}
              {agent.messenger && (
                <a
                  href={agent.messenger}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center space-x-2 bg-blue-600/20 text-blue-500 hover:bg-blue-600/30 px-4 py-2 rounded-lg transition-colors"
                >
                  <FontAwesomeIcon icon={faFacebookMessenger} />
                  <span>Messenger</span>
                </a>
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="border-t border-[#2e3348] p-4 flex justify-between">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-400 hover:text-white transition-colors"
          >
            Close
          </button>
          
          {onReport && (
            <button
              onClick={() => onReport(agent)}
              className="flex items-center space-x-2 px-4 py-2 bg-red-600/20 text-red-500 hover:bg-red-600/30 rounded-lg transition-colors"
            >
              <FontAwesomeIcon icon={faFlag} />
              <span>Report Agent</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}