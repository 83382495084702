import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { FooterModal } from './FooterModal';

export function Footer() {
  const [activeModal, setActiveModal] = useState<'terms' | 'privacy' | 'responsible' | 'help' | null>(null);

  return (
    <footer className="bg-black text-white">
      {/* Glowing Separator */}
      <div className="h-0.5 bg-gradient-to-r from-black via-yellow-500 to-black relative">
        <div className="absolute inset-0 bg-gradient-to-r from-red-500 via-yellow-500 to-red-500 blur-sm" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Gambling Warning */}
        <div className="flex items-center justify-center space-x-2 mb-6 text-yellow-500">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl" />
          <span className="text-sm font-medium">Gambling Advisory: Play Responsibly</span>
        </div>

        {/* Quick Links */}
        <div className="flex justify-center space-x-6 mb-6">
          <button 
            onClick={() => setActiveModal('terms')} 
            className="text-sm text-gray-400 hover:text-yellow-500 transition-colors"
          >
            Terms of Service
          </button>
          <span className="text-gray-600">|</span>
          <button 
            onClick={() => setActiveModal('privacy')} 
            className="text-sm text-gray-400 hover:text-yellow-500 transition-colors"
          >
            Privacy Policy
          </button>
          <span className="text-gray-600">|</span>
          <button 
            onClick={() => setActiveModal('responsible')} 
            className="text-sm text-gray-400 hover:text-yellow-500 transition-colors"
          >
            Responsible Gaming
          </button>
          <span className="text-gray-600">|</span>
          <button 
            onClick={() => setActiveModal('help')} 
            className="text-sm text-gray-400 hover:text-yellow-500 transition-colors"
          >
            Help Center
          </button>
        </div>

        {/* Gambling Policy */}
        <div className="text-center text-xs text-gray-500 max-w-2xl mx-auto mb-6">
          This website is intended for adults aged 18 and above. Gambling can be addictive. 
          Please play responsibly and within your limits. If you have a gambling problem, 
          please seek help at National Problem Gambling Helpline: 1-800-522-4700
        </div>

        {/* Copyright */}
        <div className="text-center text-xs text-gray-600">
          &copy; {new Date().getFullYear()} MagicPlay. All rights reserved.
        </div>
      </div>

      {/* Modals */}
      <FooterModal
        title="Terms of Service"
        isOpen={activeModal === 'terms'}
        onClose={() => setActiveModal(null)}
      >
        <div className="space-y-8">
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">1. Acceptance of Terms</h2>
            <p className="text-gray-300 leading-relaxed">
              By accessing and using MagicPlay's services, you agree to be bound by these Terms of Service.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">2. Eligibility</h2>
            <p className="text-gray-300 leading-relaxed">
              You must be at least 18 years old to use our services. By using our services, 
              you represent and warrant that you are at least 18 years old.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">3. Account Responsibilities</h2>
            <p className="text-gray-300 leading-relaxed">
              You are responsible for maintaining the confidentiality of your account and password. 
              You agree to accept responsibility for all activities that occur under your account.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">4. Prohibited Activities</h2>
            <ul className="list-disc pl-5 space-y-2 text-gray-300">
              <li>Using the service for any illegal purpose</li>
              <li>Attempting to interfere with the service's operation</li>
              <li>Creating multiple accounts</li>
              <li>Sharing account credentials</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">5. Service Modifications</h2>
            <p className="text-gray-300 leading-relaxed">
              We reserve the right to modify or discontinue any part of our service without notice.
            </p>
          </div>
        </div>
      </FooterModal>

      <FooterModal
        title="Privacy Policy"
        isOpen={activeModal === 'privacy'}
        onClose={() => setActiveModal(null)}
      >
        <div className="space-y-8">
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">1. Information Collection</h2>
            <p className="text-gray-300 leading-relaxed">
              We collect information that you provide directly to us, including personal information 
              such as your name, email address, and payment information.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">2. Use of Information</h2>
            <p className="text-gray-300 leading-relaxed mb-3">We use the information we collect to:</p>
            <ul className="list-disc pl-5 space-y-2 text-gray-300">
              <li>Provide and maintain our services</li>
              <li>Process your transactions</li>
              <li>Send you technical notices and support messages</li>
              <li>Communicate with you about products, services, and events</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">3. Information Security</h2>
            <p className="text-gray-300 leading-relaxed">
              We implement appropriate security measures to protect your personal information against 
              unauthorized access, alteration, disclosure, or destruction.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">4. Data Retention</h2>
            <p className="text-gray-300 leading-relaxed">
              We retain your information for as long as necessary to provide our services and 
              fulfill the purposes outlined in this Privacy Policy.
            </p>
          </div>
        </div>
      </FooterModal>

      <FooterModal
        title="Responsible Gaming"
        isOpen={activeModal === 'responsible'}
        onClose={() => setActiveModal(null)}
      >
        <div className="space-y-8">
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Commitment to Responsible Gaming</h2>
            <p className="text-gray-300 leading-relaxed">
              At MagicPlay, we are committed to promoting responsible gaming and providing a safe, 
              fair, and enjoyable gaming environment.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Self-Exclusion Program</h2>
            <p className="text-gray-300 leading-relaxed">
              We offer a self-exclusion program that allows players to voluntarily exclude 
              themselves from gaming activities.
            </p>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Setting Limits</h2>
            <p className="text-gray-300 leading-relaxed mb-3">We encourage players to:</p>
            <ul className="list-disc pl-5 space-y-2 text-gray-300">
              <li>Set deposit limits</li>
              <li>Set time limits for gaming sessions</li>
              <li>Take regular breaks</li>
              <li>Never chase losses</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Warning Signs of Problem Gambling</h2>
            <ul className="list-disc pl-5 space-y-2 text-gray-300">
              <li>Spending more money and time than you can afford</li>
              <li>Neglecting work, school, or family responsibilities</li>
              <li>Lying about gambling habits</li>
              <li>Gambling to escape problems or negative feelings</li>
            </ul>
          </div>
          
          <div className="bg-yellow-500/5 p-6 rounded-lg border border-yellow-500/20">
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Getting Help</h2>
            <p className="text-gray-300 leading-relaxed mb-2">
              If you or someone you know has a gambling problem, help is available 24/7:
            </p>
            <p className="text-yellow-500 font-semibold">
              National Problem Gambling Helpline: 1-800-522-4700
            </p>
          </div>
        </div>
      </FooterModal>

      <FooterModal
        title="Help Center"
        isOpen={activeModal === 'help'}
        onClose={() => setActiveModal(null)}
      >
        <div className="space-y-8">
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Account Management</h2>
            <div className="bg-black/30 p-4 rounded-lg border border-gray-800">
              <p className="text-white font-medium mb-2">How do I create an account?</p>
              <p className="text-gray-300 leading-relaxed">
                Contact any of our verified agents to create an account for you.
              </p>
            </div>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Technical Support</h2>
            <div className="bg-black/30 p-4 rounded-lg border border-gray-800">
              <p className="text-white font-medium mb-2">What should I do if I encounter technical issues?</p>
              <p className="text-gray-300 leading-relaxed">
                Contact your agent or our support team through the available channels.
              </p>
            </div>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Security</h2>
            <div className="bg-black/30 p-4 rounded-lg border border-gray-800">
              <p className="text-white font-medium mb-2">How can I keep my account secure?</p>
              <ul className="list-disc pl-5 space-y-2 text-gray-300">
                <li>Use a strong, unique password</li>
                <li>Never share your account credentials</li>
                <li>Enable two-factor authentication if available</li>
                <li>Report any suspicious activity immediately</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-yellow-500/5 p-6 rounded-lg border border-yellow-500/20">
            <h2 className="text-lg font-semibold text-yellow-500 mb-3">Contact Support</h2>
            <p className="text-gray-300 leading-relaxed">
              For additional assistance, please contact your agent or reach out through our official channels.
            </p>
          </div>
        </div>
      </FooterModal>
    </footer>
  );
}