import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { LoginPage } from './admin/pages/LoginPage';
import { DashboardLayout } from './admin/components/DashboardLayout';
import { DashboardPage } from './admin/pages/DashboardPage';
import { AgentListPage as AdminAgentListPage } from './admin/pages/AgentListPage';
import { ReportsPage } from './admin/pages/ReportsPage';
import { SettingsPage } from './admin/pages/SettingsPage';
import { NoticesPage } from './admin/pages/NoticesPage';
import { ProtectedRoute } from './admin/components/ProtectedRoute';
import { LandingPage } from './landing/pages/LandingPage';
import { AgentListPage } from './landing/pages/AgentListPage';
import { useAuthStore } from './store/authStore';

export default function App() {
  const { user, loading } = useAuthStore();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/company-head" element={<AgentListPage />} />
          <Route path="/admins" element={<AgentListPage />} />
          <Route path="/ss-admins" element={<AgentListPage />} />
          <Route path="/sub-admins" element={<AgentListPage />} />
          <Route path="/super-agents" element={<AgentListPage />} />
          <Route path="/master-agents" element={<AgentListPage />} />

          {/* Admin routes */}
          <Route
            path="/magicplayadm/login"
            element={
              user ? (
                <Navigate to="/magicplayadm/dashboard" replace />
              ) : (
                <LoginPage />
              )
            }
          />

          <Route
            path="/magicplayadm"
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            {/* Index route redirect */}
            <Route index element={<Navigate to="/magicplayadm/dashboard" replace />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="company-head" element={<AdminAgentListPage />} />
            <Route path="admins" element={<AdminAgentListPage />} />
            <Route path="ss-admins" element={<AdminAgentListPage />} />
            <Route path="sub-admins" element={<AdminAgentListPage />} />
            <Route path="super-agents" element={<AdminAgentListPage />} />
            <Route path="master-agents" element={<AdminAgentListPage />} />
            <Route path="notices" element={<NoticesPage />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
      <Toaster position="top-right" />
    </>
  );
}