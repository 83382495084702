import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUserTie, 
  faUserShield, 
  faUsers,
  faChevronDown,
  faHome,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { SearchBox } from './SearchBox';

const agentCategories = [
  { path: '/company-head', label: 'Company Head', icon: faUserTie },
  { path: '/admins', label: 'Admins', icon: faUserShield },
  { path: '/ss-admins', label: 'SS Admins', icon: faUserShield },
  { path: '/sub-admins', label: 'Sub Admins', icon: faUserShield },
  { path: '/super-agents', label: 'Super Agents', icon: faUsers },
  { path: '/master-agents', label: 'Master Agents', icon: faUsers },
];

export function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="bg-[#0f1015] border-b border-[#2e3348]">
      <nav className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="flex items-center justify-between h-16">
            {/* Mobile Left - Search */}
            <div className="lg:hidden flex-1">
              <SearchBox />
            </div>

            {/* Logo - Left on desktop, center on mobile */}
            <div className="flex-1 flex items-center lg:flex-none lg:w-48">
              <Link to="/" className="flex items-center h-16">
                <img src="https://magicplay247.com/static/media/logo.8af21274a4c9991f172c.png" alt="MagicPlay Logo" className="h-8 w-auto" />
              </Link>
            </div>

            {/* Desktop Navigation - Centered */}
            <div className="hidden lg:flex flex-1 items-center justify-center space-x-2">
              <Link 
                to="/" 
                className={`px-3 py-1.5 rounded-md flex items-center space-x-1.5 text-sm transition-all duration-200 whitespace-nowrap
                  ${location.pathname === '/' ? 'bg-[#EAB308] text-white' : 'text-gray-400 hover:text-white hover:bg-[#DC2626]'}`}
              >
                <FontAwesomeIcon icon={faHome} className="text-xs" />
                <span>Home</span>
              </Link>
              
              {agentCategories.map((category) => (
                <Link
                  key={category.path}
                  to={category.path}
                  className={`px-3 py-1.5 rounded-md flex items-center space-x-1.5 text-sm transition-all duration-200 whitespace-nowrap
                    ${location.pathname === category.path ? 'bg-[#EAB308] text-white' : 'text-gray-400 hover:text-white hover:bg-[#DC2626]'}`}
                >
                  <FontAwesomeIcon icon={category.icon} className="text-xs" />
                  <span>{category.label}</span>
                </Link>
              ))}
            </div>

            {/* Desktop Search - Right */}
            <div className="hidden lg:block lg:w-48">
              <div className="absolute top-0 right-8">
                <SearchBox />
              </div>
            </div>

            {/* Mobile Right - Menu Button */}
            <div className="lg:hidden flex-1 flex justify-end">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="p-2 rounded-md text-white bg-[#DC2626] hover:bg-[#EAB308] transition-all duration-200"
                aria-label="Menu"
              >
                <FontAwesomeIcon icon={faBars} className="text-lg" />
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Sliding Menu */}
        <div 
          className={`fixed top-0 right-0 h-full w-64 bg-[#1f2231] transform transition-transform duration-300 ease-in-out z-50 lg:hidden ${
            isDropdownOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex flex-col h-full">
            <div className="p-4 border-b border-[#2e3348] flex justify-between items-center">
              <span className="text-white font-semibold">Menu</span>
              <button
                onClick={() => setIsDropdownOpen(false)}
                className="p-2 rounded-md text-gray-400 hover:text-white hover:bg-[#DC2626] transition-all duration-200"
              >
                <FontAwesomeIcon icon={faChevronDown} className="rotate-90" />
              </button>
            </div>
            
            <div className="flex-1 overflow-y-auto">
              <Link
                to="/"
                className={`flex items-center space-x-2 px-4 py-3 text-sm transition-all duration-200
                  ${location.pathname === '/' ? 'bg-[#EAB308] text-white' : 'text-gray-400 hover:text-white hover:bg-[#DC2626]'}`}
                onClick={() => setIsDropdownOpen(false)}
              >
                <FontAwesomeIcon icon={faHome} className="text-xs" />
                <span>Home</span>
              </Link>
              {agentCategories.map((category) => (
                <Link
                  key={category.path}
                  to={category.path}
                  className={`flex items-center space-x-2 px-4 py-3 text-sm transition-all duration-200
                    ${location.pathname === category.path ? 'bg-[#EAB308] text-white' : 'text-gray-400 hover:text-white hover:bg-[#DC2626]'}`}
                  onClick={() => setIsDropdownOpen(false)}
                >
                  <FontAwesomeIcon icon={category.icon} className="text-xs" />
                  <span>{category.label}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Overlay */}
        {isDropdownOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
            onClick={() => setIsDropdownOpen(false)}
          />
        )}
      </nav>

      {/* Mobile Horizontal Scrollable Menu */}
      <div className="lg:hidden overflow-x-auto scrollbar-none border-t border-[#2e3348]">
        <div className="flex space-x-2 px-4 py-2 min-w-max">
          <Link 
            to="/" 
            className={`px-3 py-1.5 rounded-md flex items-center space-x-1.5 text-sm transition-all duration-200 whitespace-nowrap
              ${location.pathname === '/' ? 'bg-[#EAB308] text-white' : 'bg-[#1f2231] text-gray-400 hover:text-white hover:bg-[#DC2626]'}`}
          >
            <FontAwesomeIcon icon={faHome} className="text-xs" />
            <span>Home</span>
          </Link>
          
          {agentCategories.map((category) => (
            <Link
              key={category.path}
              to={category.path}
              className={`px-3 py-1.5 rounded-md flex items-center space-x-1.5 text-sm transition-all duration-200 whitespace-nowrap
                ${location.pathname === category.path ? 'bg-[#EAB308] text-white' : 'bg-[#1f2231] text-gray-400 hover:text-white hover:bg-[#DC2626]'}`}
            >
              <FontAwesomeIcon icon={category.icon} className="text-xs" />
              <span>{category.label}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}