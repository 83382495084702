import { useState, useEffect } from 'react';
import { Agent, AgentRole } from '../../types/agent';
import { useAgentStore } from '../../store/agentStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';

interface AgentFormProps {
  mode: 'add' | 'edit';
  role?: AgentRole;
  initialData?: Agent;
  onSubmit: (data: any) => Promise<void>;
  onCancel: () => void;
}

export function AgentForm({ mode, role, initialData, onSubmit, onCancel }: AgentFormProps) {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    whatsapp: initialData?.whatsapp || '',
    messenger: initialData?.messenger || 'https://m.me/',
    uplineId: initialData?.uplineId || '',
  });
  
  const [uplineOptions, setUplineOptions] = useState<Agent[]>([]);
  const getUplineOptions = useAgentStore((state) => state.getUplineOptions);
  
  useEffect(() => {
    if (role && role !== 'COMPANY_HEAD') {
      getUplineOptions(role).then(setUplineOptions);
    }
  }, [role]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      ...formData,
      role: role || initialData?.role,
    };
    await onSubmit(data);
  };

  const handleMessengerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    // Remove the prefix if user tries to type it
    value = value.replace('https://m.me/', '');
    // Set the complete URL
    setFormData({ ...formData, messenger: `https://m.me/${value}` });
  };

  // Extract username from messenger URL for display
  const messengerUsername = formData.messenger.replace('https://m.me/', '');

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        />
      </div>

      <div className="space-y-4">
        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            <FontAwesomeIcon icon={faWhatsapp} className="mr-2 text-green-500" />
            WhatsApp Number
          </label>
          <input
            type="text"
            value={formData.whatsapp}
            onChange={(e) => setFormData({ ...formData, whatsapp: e.target.value })}
            placeholder="Enter WhatsApp number with country code"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="flex items-center text-sm font-medium text-gray-700">
            <FontAwesomeIcon icon={faFacebookMessenger} className="mr-2 text-blue-500" />
            Messenger Username
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              https://m.me/
            </span>
            <input
              type="text"
              value={messengerUsername}
              onChange={handleMessengerChange}
              placeholder="username"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Enter your Facebook username (e.g., "john.doe")
          </p>
        </div>
      </div>

      {role && role !== 'COMPANY_HEAD' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Upline</label>
          <select
            value={formData.uplineId}
            onChange={(e) => setFormData({ ...formData, uplineId: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          >
            <option value="">Select Upline</option>
            {uplineOptions.map((upline) => (
              <option key={upline.id} value={upline.id}>
                {upline.name} ({upline.magicId})
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
        >
          {mode === 'add' ? 'Add Agent' : 'Update Agent'}
        </button>
      </div>
    </form>
  );
}