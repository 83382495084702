import { useState } from 'react';
import { Agent } from '../../types/agent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faFlag } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { AgentModal } from './AgentModal';
import { ReportModal } from './ReportModal';

interface AgentTableProps {
  agents: Agent[];
}

export function AgentTable({ agents }: AgentTableProps) {
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [reportingAgent, setReportingAgent] = useState<Agent | null>(null);

  return (
    <>
      <div className="bg-black rounded-lg shadow-xl overflow-x-auto border border-yellow-500/20">
        <div className="min-w-full">
          <table className="w-full divide-y divide-yellow-500/20">
            <thead className="bg-yellow-500/10">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-yellow-500 uppercase tracking-wider whitespace-nowrap">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-yellow-500 uppercase tracking-wider whitespace-nowrap">
                  Magic ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-yellow-500 uppercase tracking-wider whitespace-nowrap">
                  Social
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-yellow-500 uppercase tracking-wider whitespace-nowrap">
                  Rating
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-yellow-500 uppercase tracking-wider whitespace-nowrap">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-yellow-500/20">
              {agents.map((agent) => (
                <tr key={agent.id} className="hover:bg-yellow-500/5 transition-colors duration-200">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-yellow-500">{agent.name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-400">{agent.magicId}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex space-x-3">
                      {agent.whatsapp && (
                        <a
                          href={`https://wa.me/${agent.whatsapp}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-500 hover:text-green-400 transition-colors duration-200"
                        >
                          <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                        </a>
                      )}
                      {agent.messenger && (
                        <a
                          href={agent.messenger}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:text-blue-400 transition-colors duration-200"
                        >
                          <FontAwesomeIcon icon={faFacebookMessenger} size="lg" />
                        </a>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex text-yellow-500">
                      {[...Array(5)].map((_, i) => (
                        <FontAwesomeIcon
                          key={i}
                          icon={faStar}
                          className={i < (agent.rating || 5) ? 'text-yellow-500' : 'text-gray-600'}
                        />
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-4">
                    <button
                      onClick={() => setSelectedAgent(agent)}
                      className="text-yellow-500 hover:text-yellow-400 transition-colors duration-200"
                    >
                      View
                    </button>
                    <button
                      onClick={() => setReportingAgent(agent)}
                      className="text-red-500 hover:text-red-400 transition-colors duration-200"
                    >
                      <FontAwesomeIcon icon={faFlag} className="mr-1" />
                      Report
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedAgent && (
        <AgentModal
          agent={selectedAgent}
          onClose={() => setSelectedAgent(null)}
          onReport={setReportingAgent}
        />
      )}

      {reportingAgent && (
        <ReportModal
          agent={reportingAgent}
          onClose={() => setReportingAgent(null)}
        />
      )}
    </>
  );
}