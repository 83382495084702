import Marquee from 'react-fast-marquee';
import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

interface Notice {
  id: string;
  content: string;
  createdAt: Date;
}

export function NoticeBar() {
  const [notices, setNotices] = useState<Notice[]>([]);

  useEffect(() => {
    const fetchNotices = async () => {
      const noticesRef = collection(db, 'notices');
      const q = query(noticesRef, orderBy('createdAt', 'desc'), limit(5));
      const snapshot = await getDocs(q);
      
      const fetchedNotices = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate()
      })) as Notice[];
      
      setNotices(fetchedNotices);
    };

    fetchNotices();
  }, []);

  if (notices.length === 0) return null;

  return (
    <div className="bg-red-600 border-y border-red-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="flex items-center">
          <div className="flex-shrink-0 text-white mr-4">
            <FontAwesomeIcon icon={faBullhorn} size="lg" />
          </div>
          <Marquee gradient={false} speed={50}>
            <div className="flex space-x-12">
              {notices.map((notice) => (
                <span key={notice.id} className="text-white font-medium">
                  {notice.content}
                </span>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}