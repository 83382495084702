import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AgentTable } from '../components/AgentTable';
import { AgentFormModal } from '../components/AgentFormModal';
import { useAgentStore } from '../../store/agentStore';
import { Agent, AgentRole } from '../../types/agent';
import toast from 'react-hot-toast';

const roleMap: Record<string, AgentRole> = {
  'company-head': 'COMPANY_HEAD',
  'admins': 'ADMIN',
  'ss-admins': 'SS_ADMIN',
  'sub-admins': 'SUB_ADMIN',
  'super-agents': 'SUPER_AGENT',
  'master-agents': 'MASTER_AGENT',
};

const roleDisplayNames: Record<AgentRole, string> = {
  'COMPANY_HEAD': 'Company Head',
  'ADMIN': 'Admin',
  'SS_ADMIN': 'SS Admin',
  'SUB_ADMIN': 'Sub Admin',
  'SUPER_AGENT': 'Super Agent',
  'MASTER_AGENT': 'Master Agent',
};

export function AgentListPage() {
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop() || '';
  const role = roleMap[currentPath] as AgentRole;
  
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingAgent, setEditingAgent] = useState<Agent | null>(null);
  
  const { agents, loading, fetchAgents, addAgent, updateAgent } = useAgentStore();

  useEffect(() => {
    if (role) {
      fetchAgents(role);
    }
  }, [role, fetchAgents]);

  const handleAdd = async (data: any) => {
    try {
      await addAgent({ ...data, role });
      setIsAddModalOpen(false);
      toast.success(`${roleDisplayNames[role]} added successfully!`);
    } catch (error) {
      toast.error(`Failed to add ${roleDisplayNames[role].toLowerCase()}`);
    }
  };

  const handleEdit = async (data: any) => {
    if (!editingAgent) return;
    try {
      await updateAgent(editingAgent.id, data);
      setEditingAgent(null);
      toast.success(`${roleDisplayNames[role]} updated successfully!`);
    } catch (error) {
      toast.error(`Failed to update ${roleDisplayNames[role].toLowerCase()}`);
    }
  };

  if (!role) {
    return <div>Invalid role</div>;
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">
          {roleDisplayNames[role]}s
        </h1>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <FontAwesomeIcon icon={faPlus} className="w-5 h-5 mr-2" />
          Add New {roleDisplayNames[role]}
        </button>
      </div>

      <AgentTable
        agents={agents}
        onEdit={setEditingAgent}
      />

      {isAddModalOpen && (
        <AgentFormModal
          mode="add"
          role={role}
          onSubmit={handleAdd}
          onClose={() => setIsAddModalOpen(false)}
        />
      )}

      {editingAgent && (
        <AgentFormModal
          mode="edit"
          agent={editingAgent}
          onSubmit={handleEdit}
          onClose={() => setEditingAgent(null)}
        />
      )}
    </div>
  );
}