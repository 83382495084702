import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faGauge,
  faBuilding,
  faUsers,
  faFileLines,
  faRightFromBracket,
  faCog,
  faBullhorn
} from '@fortawesome/free-solid-svg-icons';
import { useAuthStore } from '../../store/authStore';

const navItems = [
  { path: '/magicplayadm/dashboard', icon: faGauge, label: 'Dashboard' },
  { path: '/magicplayadm/company-head', icon: faBuilding, label: 'Company Head' },
  { path: '/magicplayadm/admins', icon: faUsers, label: 'Admins' },
  { path: '/magicplayadm/ss-admins', icon: faUsers, label: 'SS Admins' },
  { path: '/magicplayadm/sub-admins', icon: faUsers, label: 'Sub Admins' },
  { path: '/magicplayadm/super-agents', icon: faUsers, label: 'Super Agents' },
  { path: '/magicplayadm/master-agents', icon: faUsers, label: 'Master Agents' },
  { path: '/magicplayadm/notices', icon: faBullhorn, label: 'Notices' },
  { path: '/magicplayadm/reports', icon: faFileLines, label: 'Reports' },
  { path: '/magicplayadm/settings', icon: faCog, label: 'Settings' },
];

export function Sidebar() {
  const location = useLocation();
  const signOut = useAuthStore((state) => state.signOut);

  return (
    <div className="w-64 bg-gray-900 text-white h-screen fixed left-0 top-0 flex flex-col">
      <div className="p-5">
        <h1 className="text-2xl font-bold">MagicPlay Admin</h1>
      </div>
      
      <nav className="flex-1 overflow-y-auto">
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          
          return (
            <Link
              key={item.path}
              to={item.path}
              className={`flex items-center px-5 py-3 space-x-3 transition-colors ${
                isActive 
                  ? 'bg-blue-600 text-white' 
                  : 'text-gray-300 hover:bg-gray-800'
              }`}
            >
              <FontAwesomeIcon icon={item.icon} className="w-5 h-5" />
              <span>{item.label}</span>
            </Link>
          );
        })}
      </nav>
      
      <button
        onClick={() => signOut()}
        className="flex items-center px-5 py-4 space-x-3 text-gray-300 hover:bg-gray-800 transition-colors"
      >
        <FontAwesomeIcon icon={faRightFromBracket} className="w-5 h-5" />
        <span>Sign Out</span>
      </button>
    </div>
  );
}