import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useNoticeStore } from '../../store/noticeStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';

interface Notice {
  id: string;
  content: string;
  createdAt: Timestamp;
}

export function NoticesPage() {
  const [notices, setNotices] = useState<Notice[]>([]);
  const [newNotice, setNewNotice] = useState('');
  const [editingNotice, setEditingNotice] = useState<Notice | null>(null);
  const { addNotice, updateNotice, deleteNotice } = useNoticeStore();

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const noticesRef = collection(db, 'notices');
      const q = query(noticesRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      
      const fetchedNotices = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Notice[];
      
      setNotices(fetchedNotices);
    } catch (error) {
      toast.error('Failed to fetch notices');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newNotice.trim()) return;

    try {
      await addNotice(newNotice);
      setNewNotice('');
      toast.success('Notice added successfully');
      fetchNotices();
    } catch (error) {
      toast.error('Failed to add notice');
    }
  };

  const handleUpdate = async (id: string, content: string) => {
    try {
      await updateNotice(id, content);
      setEditingNotice(null);
      toast.success('Notice updated successfully');
      fetchNotices();
    } catch (error) {
      toast.error('Failed to update notice');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this notice?')) return;

    try {
      await deleteNotice(id);
      toast.success('Notice deleted successfully');
      fetchNotices();
    } catch (error) {
      toast.error('Failed to delete notice');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Notices</h1>
        <div className="flex items-center space-x-2 text-gray-500">
          <FontAwesomeIcon icon={faBullhorn} className="w-5 h-5" />
          <span>Notice Management</span>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            New Notice
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              value={newNotice}
              onChange={(e) => setNewNotice(e.target.value)}
              className="flex-1 rounded-l-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter notice content..."
            />
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700"
            >
              <FontAwesomeIcon icon={faPlus} className="w-4 h-4 mr-2" />
              Add Notice
            </button>
          </div>
        </div>
      </form>

      <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
        {notices.map((notice) => (
          <div key={notice.id} className="p-4">
            {editingNotice?.id === notice.id ? (
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={editingNotice.content}
                  onChange={(e) => setEditingNotice({ ...editingNotice, content: e.target.value })}
                  className="flex-1 rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                />
                <button
                  onClick={() => handleUpdate(notice.id, editingNotice.content)}
                  className="px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700"
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingNotice(null)}
                  className="px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">
                    {notice.createdAt.toDate().toLocaleString()}
                  </p>
                  <p className="mt-1 text-gray-900">{notice.content}</p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setEditingNotice(notice)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                  <button
                    onClick={() => handleDelete(notice.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}