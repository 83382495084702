import { useEffect, useState } from 'react';
import { Agent } from '../../types/agent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface AgentModalProps {
  agent: Agent;
  onClose: () => void;
}

export function AgentModal({ agent, onClose }: AgentModalProps) {
  const [upline, setUpline] = useState<Agent | null>(null);

  useEffect(() => {
    const fetchUpline = async () => {
      if (agent.uplineId) {
        try {
          const uplineDoc = await getDoc(doc(db, 'agents', agent.uplineId));
          if (uplineDoc.exists()) {
            setUpline({ id: uplineDoc.id, ...uplineDoc.data() } as Agent);
          }
        } catch (error) {
          console.error('Error fetching upline:', error);
        }
      }
    };

    fetchUpline();
  }, [agent.uplineId]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">Agent Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        
        <div className="p-4 space-y-4">
          <div>
            <label className="text-sm font-medium text-gray-500">Name</label>
            <p className="text-gray-900">{agent.name}</p>
          </div>
          
          <div>
            <label className="text-sm font-medium text-gray-500">Magic ID</label>
            <p className="text-gray-900">{agent.magicId}</p>
          </div>
          
          <div>
            <label className="text-sm font-medium text-gray-500">Role</label>
            <p className="text-gray-900">{agent.role.replace('_', ' ')}</p>
          </div>

          {upline && (
            <div>
              <label className="text-sm font-medium text-gray-500">Upline</label>
              <p className="text-gray-900">
                {upline.name} ({upline.magicId}) - {upline.role.replace('_', ' ')}
              </p>
            </div>
          )}
          
          <div>
            <label className="text-sm font-medium text-gray-500">Rating</label>
            <div className="flex text-yellow-400">
              {[...Array(5)].map((_, i) => (
                <FontAwesomeIcon
                  key={i}
                  icon={faStar}
                  className="text-yellow-400"
                />
              ))}
            </div>
          </div>
          
          <div>
            <label className="text-sm font-medium text-gray-500">Contact</label>
            <div className="flex space-x-4 mt-1">
              {agent.whatsapp && (
                <a
                  href={`https://wa.me/${agent.whatsapp}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-2 text-green-500 hover:text-green-600"
                >
                  <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                  <span>WhatsApp</span>
                </a>
              )}
              {agent.messenger && (
                <a
                  href={agent.messenger}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-2 text-blue-500 hover:text-blue-600"
                >
                  <FontAwesomeIcon icon={faFacebookMessenger} size="lg" />
                  <span>Messenger</span>
                </a>
              )}
            </div>
          </div>

          <div>
            <label className="text-sm font-medium text-gray-500">Created At</label>
            <p className="text-gray-900">
              {agent.createdAt.toLocaleDateString()} {agent.createdAt.toLocaleTimeString()}
            </p>
          </div>

          <div>
            <label className="text-sm font-medium text-gray-500">Last Updated</label>
            <p className="text-gray-900">
              {agent.updatedAt.toLocaleDateString()} {agent.updatedAt.toLocaleTimeString()}
            </p>
          </div>
        </div>
        
        <div className="p-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}