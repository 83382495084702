import { useEffect, useState } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Agent, AgentRole } from '../../types/agent';
import { BarChart3, Users } from 'lucide-react';

export function DashboardPage() {
  const [stats, setStats] = useState<{ [key in AgentRole]: number }>({
    COMPANY_HEAD: 0,
    ADMIN: 0,
    SS_ADMIN: 0,
    SUB_ADMIN: 0,
    SUPER_AGENT: 0,
    MASTER_AGENT: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      const agentsRef = collection(db, 'agents');
      const snapshot = await getDocs(query(agentsRef));
      
      const newStats = { ...stats };
      snapshot.forEach((doc) => {
        const agent = doc.data() as Agent;
        newStats[agent.role]++;
      });
      
      setStats(newStats);
    };

    fetchStats();
  }, []);

  const StatCard = ({ title, count }: { title: string; count: number }) => (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-500 text-sm">{title}</p>
          <p className="text-2xl font-semibold mt-1">{count}</p>
        </div>
        <div className="bg-blue-100 p-3 rounded-full">
          <Users className="text-blue-600" size={24} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        <div className="flex items-center space-x-2 text-gray-500">
          <BarChart3 size={20} />
          <span>Overview</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatCard title="Company Heads" count={stats.COMPANY_HEAD} />
        <StatCard title="Admins" count={stats.ADMIN} />
        <StatCard title="SS Admins" count={stats.SS_ADMIN} />
        <StatCard title="Sub Admins" count={stats.SUB_ADMIN} />
        <StatCard title="Super Agents" count={stats.SUPER_AGENT} />
        <StatCard title="Master Agents" count={stats.MASTER_AGENT} />
      </div>
    </div>
  );
}