import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faLink, faUserGroup, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Agent } from '../../types/agent';
import { QuickAgentTable } from './QuickAgentTable';

export function QuickLinks() {
  const [masterAgents, setMasterAgents] = useState<Agent[]>([]);

  useEffect(() => {
    const fetchRandomMasterAgents = async () => {
      try {
        const agentsRef = collection(db, 'agents');
        const q = query(
          agentsRef,
          where('role', '==', 'MASTER_AGENT'),
          limit(2)
        );
        const snapshot = await getDocs(q);
        
        const agents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt.toDate(),
        })) as Agent[];
        
        setMasterAgents(agents);
      } catch (error) {
        console.error('Error fetching master agents:', error);
      }
    };

    fetchRandomMasterAgents();
  }, []);

  return (
    <div className="py-12 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Quick Master Agents */}
          <div className="lg:col-span-1">
            <div className="bg-gray-900 rounded-lg shadow-lg overflow-hidden border border-yellow-500/20">
              <div className="p-4 border-b border-yellow-500/20">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faUserTie} className="text-yellow-500 text-xl mr-3" />
                  <h3 className="text-lg font-semibold text-yellow-500">Quick Master Agents</h3>
                </div>
              </div>
              <div className="max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-yellow-500/20 scrollbar-track-transparent">
                <QuickAgentTable agents={masterAgents} />
              </div>
            </div>
          </div>

          {/* Facebook Group */}
          <div className="lg:col-span-1">
            <div className="bg-gray-900 rounded-lg shadow-lg overflow-hidden border border-yellow-500/20">
              <div className="p-4">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 rounded-lg bg-[#1877F2] flex items-center justify-center">
                    <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-lg font-semibold text-white">Official Group</h3>
                    <p className="text-sm text-gray-400">Join our community</p>
                  </div>
                </div>
                <a
                  href="https://facebook.com/groups/magicplay"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-full bg-[#1877F2] hover:bg-[#1864D4] text-white px-4 py-2.5 rounded-md transition-colors font-medium text-sm"
                >
                  <FontAwesomeIcon icon={faUserGroup} className="mr-2" />
                  Join Group
                </a>
              </div>
            </div>
          </div>

          {/* Our Links */}
          <div className="lg:col-span-1">
            <div className="bg-gray-900 rounded-lg shadow-lg overflow-hidden border border-yellow-500/20">
              <div className="p-4">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-600 flex items-center justify-center">
                    <FontAwesomeIcon icon={faLink} className="text-black text-xl" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-lg font-semibold text-white">Our Links</h3>
                    <p className="text-sm text-gray-400">Official domains</p>
                  </div>
                </div>
                
                {/* Primary Site */}
                <div className="mb-3">
                  <div className="flex items-center justify-between bg-black/30 rounded-lg p-3 border border-yellow-500/10">
                    <div>
                      <h4 className="text-yellow-500 font-medium">Primary Site</h4>
                      <p className="text-sm text-gray-400">magicplay247.com</p>
                    </div>
                    <a
                      href="https://magicplay247.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center px-3 py-1.5 bg-yellow-500 hover:bg-yellow-400 text-black rounded-md transition-colors text-sm font-medium"
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-1.5" />
                      Visit
                    </a>
                  </div>
                </div>

                {/* Agent List Site */}
                <div>
                  <div className="flex items-center justify-between bg-black/30 rounded-lg p-3 border border-yellow-500/10">
                    <div>
                      <h4 className="text-yellow-500 font-medium">Agent List</h4>
                      <p className="text-sm text-gray-400">magicplay247.info</p>
                    </div>
                    <a
                      href="https://magicplay247.info"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center px-3 py-1.5 bg-yellow-500 hover:bg-yellow-400 text-black rounded-md transition-colors text-sm font-medium"
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-1.5" />
                      Visit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}