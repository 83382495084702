import { create } from 'zustand';
import { 
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Agent, AgentRole } from '../types/agent';
import { generateMagicId, generateRandomRating } from '../lib/utils';

interface AgentState {
  agents: Agent[];
  loading: boolean;
  error: string | null;
  fetchAgents: (role: AgentRole) => Promise<void>;
  addAgent: (data: Omit<Agent, 'id' | 'magicId' | 'rating' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateAgent: (id: string, data: Partial<Agent>) => Promise<void>;
  getUplineOptions: (role: AgentRole) => Promise<Agent[]>;
}

export const useAgentStore = create<AgentState>((set, get) => ({
  agents: [],
  loading: false,
  error: null,

  fetchAgents: async (role: AgentRole) => {
    set({ loading: true, error: null });
    try {
      const agentsRef = collection(db, 'agents');
      const q = query(agentsRef, where('role', '==', role));
      const snapshot = await getDocs(q);
      
      const agents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: (doc.data().createdAt as Timestamp).toDate(),
        updatedAt: (doc.data().updatedAt as Timestamp).toDate(),
      })) as Agent[];
      
      set({ agents, loading: false });
    } catch (error) {
      console.error('Error fetching agents:', error);
      set({ error: 'Failed to fetch agents', loading: false });
    }
  },

  addAgent: async (data) => {
    set({ loading: true, error: null });
    try {
      const newAgent = {
        ...data,
        magicId: generateMagicId(),
        rating: generateRandomRating(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'agents'), newAgent);
      await get().fetchAgents(data.role);
      set({ loading: false });
    } catch (error) {
      console.error('Error adding agent:', error);
      set({ error: 'Failed to add agent', loading: false });
      throw error;
    }
  },

  updateAgent: async (id, data) => {
    set({ loading: true, error: null });
    try {
      const agentRef = doc(db, 'agents', id);
      const updateData = {
        ...data,
        updatedAt: serverTimestamp(),
      };
      await updateDoc(agentRef, updateData);
      
      const currentAgent = get().agents.find(agent => agent.id === id);
      if (currentAgent) {
        await get().fetchAgents(currentAgent.role);
      }
      set({ loading: false });
    } catch (error) {
      console.error('Error updating agent:', error);
      set({ error: 'Failed to update agent', loading: false });
      throw error;
    }
  },

  getUplineOptions: async (role: AgentRole) => {
    const uplineRoles = {
      MASTER_AGENT: ['SUPER_AGENT'],
      SUPER_AGENT: ['SUB_ADMIN'],
      SUB_ADMIN: ['SS_ADMIN'],
      SS_ADMIN: ['ADMIN'],
      ADMIN: ['COMPANY_HEAD'],
      COMPANY_HEAD: [],
    };

    const validUplineRoles = uplineRoles[role];
    if (!validUplineRoles.length) return [];

    try {
      const agentsRef = collection(db, 'agents');
      const q = query(agentsRef, where('role', 'in', validUplineRoles));
      const snapshot = await getDocs(q);
      
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: (doc.data().createdAt as Timestamp).toDate(),
        updatedAt: (doc.data().updatedAt as Timestamp).toDate(),
      })) as Agent[];
    } catch (error) {
      console.error('Error fetching upline options:', error);
      return [];
    }
  },
}));