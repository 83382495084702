import { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Agent } from '../../types/agent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, 
  faTimes, 
  faUser,
  faIdCard,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { 
  faWhatsapp,
  faFacebookMessenger 
} from '@fortawesome/free-brands-svg-icons';

export function SearchBox() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Agent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [recentAgents, setRecentAgents] = useState<Agent[]>([]);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        handleClose();
      }
    }

    function handleEscape(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handleClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  useEffect(() => {
    if (showSearch) {
      fetchRecentAgents();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [showSearch]);

  const fetchRecentAgents = async () => {
    setIsLoading(true);
    try {
      const agentsRef = collection(db, 'agents');
      const q = query(
        agentsRef, 
        orderBy('createdAt', 'desc'), 
        limit(5)
      );
      
      const snapshot = await getDocs(q);
      const agents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate(),
      })) as Agent[];

      setRecentAgents(agents);
    } catch (error) {
      console.error('Error fetching recent agents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    
    if (value.trim().length < 2) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);

    try {
      const agentsRef = collection(db, 'agents');
      const searchValue = value.trim().toUpperCase(); // Convert to uppercase for magicId
      const searchValueLower = value.trim().toLowerCase(); // Convert to lowercase for name
      let results: Agent[] = [];

      // Try exact magicId match first (case-insensitive)
      const idQuery = query(
        agentsRef, 
        where('magicId', '>=', searchValue),
        where('magicId', '<=', searchValue + '\uf8ff'),
        limit(10)
      );
      const idSnapshot = await getDocs(idQuery);

      if (!idSnapshot.empty) {
        results = idSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate(),
          updatedAt: doc.data().updatedAt?.toDate(),
        })) as Agent[];
      }

      // If no ID matches, search by name
      if (results.length === 0) {
        const nameQuery = query(
          agentsRef,
          where('nameLower', '>=', searchValueLower),
          where('nameLower', '<=', searchValueLower + '\uf8ff'),
          limit(10)
        );

        const nameSnapshot = await getDocs(nameQuery);
        if (!nameSnapshot.empty) {
          results = nameSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
            updatedAt: doc.data().updatedAt?.toDate(),
          })) as Agent[];
        }
      }

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching agents:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShowSearch(false);
    setSearchTerm('');
    setSearchResults([]);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const renderAgentList = (agents: Agent[]) => (
    <div className="max-h-96 overflow-y-auto">
      {agents.map((agent) => (
        <div
          key={agent.id}
          className="px-4 py-3 hover:bg-[#2e3348] transition-colors duration-150 cursor-pointer border-b border-[#2e3348] last:border-0"
        >
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faUser} className="text-yellow-500" />
                <span className="text-white text-sm font-medium">{agent.name}</span>
              </div>
              <div className="text-xs text-yellow-500 font-medium">
                {agent.role.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}
              </div>
            </div>
            <div className="flex items-center space-x-2 text-gray-400 text-xs">
              <FontAwesomeIcon icon={faIdCard} />
              <span>ID: {agent.magicId}</span>
            </div>
            <div className="flex items-center space-x-3">
              {agent.whatsapp && (
                <a
                  href={`https://wa.me/${agent.whatsapp}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-1 text-green-500 hover:text-green-400 text-sm"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                  <span className="text-xs">WhatsApp</span>
                </a>
              )}
              {agent.messenger && (
                <a
                  href={agent.messenger}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-1 text-blue-500 hover:text-blue-400 text-sm"
                >
                  <FontAwesomeIcon icon={faFacebookMessenger} />
                  <span className="text-xs">Messenger</span>
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="relative" ref={searchRef}>
      {/* Search Icon */}
      <button
        onClick={() => setShowSearch(true)}
        className="p-2 bg-[#DC2626] text-white hover:bg-[#D49705] hover:text-white rounded-md transition-colors duration-150 mt-2"
      >
        <FontAwesomeIcon icon={faSearch} className="text-lg" />
      </button>

      {/* Search Panel */}
      {showSearch && (
        <div className="fixed lg:absolute top-0 lg:top-auto left-0 lg:left-auto right-0 lg:right-0 w-full lg:w-96 h-full lg:h-auto bg-[#1f2231] lg:rounded-md border-l lg:border border-[#2e3348] shadow-lg overflow-hidden z-50">
          {/* Search Header */}
          <div className="sticky top-0 flex items-center justify-between px-4 py-3 border-b border-[#2e3348] bg-[#2e3348]/30 backdrop-blur-sm">
            <h3 className="text-sm font-medium text-white">Search Agents</h3>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-white transition-colors duration-150"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>

          {/* Search Input */}
          <div className="sticky top-[57px] p-3 border-b border-[#2e3348] bg-[#1f2231]">
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search by ID or Name..."
                className="w-full bg-[#2e3348] rounded-md pl-10 pr-8 py-2 text-sm text-white placeholder-gray-400 focus:outline-none"
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors duration-150"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )}
            </div>
          </div>

          {/* Search Results */}
          <div className="h-[calc(100vh-120px)] lg:h-auto lg:max-h-96 overflow-y-auto">
            {isLoading ? (
              <div className="flex items-center justify-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
              </div>
            ) : searchTerm ? (
              searchResults.length > 0 ? (
                renderAgentList(searchResults)
              ) : (
                <div className="p-8 text-center text-gray-400">
                  No agents found matching "{searchTerm}"
                </div>
              )
            ) : (
              <>
                <div className="px-4 py-2 text-xs font-medium text-gray-400 bg-[#2e3348]/30">
                  Recent Agents
                </div>
                {renderAgentList(recentAgents)}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
