import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface FooterModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function FooterModal({ title, isOpen, onClose, children }: FooterModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-[#0f1015] rounded-xl w-full max-w-2xl border border-yellow-500/20 shadow-xl max-h-[80vh] flex flex-col animate-fadeIn">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-yellow-500/20 bg-black/40">
          <div className="flex items-center space-x-3">
            <div className="w-1 h-6 bg-yellow-500 rounded-full"></div>
            <h3 className="text-xl font-bold text-white">{title}</h3>
          </div>
          <button 
            onClick={onClose}
            className="h-8 w-8 rounded-lg border border-gray-800 flex items-center justify-center text-gray-400 hover:text-white hover:border-gray-600 transition-colors"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        
        {/* Content */}
        <div className="p-6 overflow-y-auto custom-scrollbar">
          <div className="space-y-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
