import './utils/console';  // Import console utility first
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ErrorBoundary } from './components/ErrorBoundary';
import './index.css';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

// Add error boundary
window.addEventListener('error', (event) => {
  console.error('Global error:', event.error);
  rootElement.innerHTML = '<div style="padding: 20px; text-align: center;"><h1>Something went wrong</h1><p>Please try refreshing the page.</p></div>';
});

root.render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);