import { Agent, AgentRole } from '../../types/agent';
import { AgentForm } from './AgentForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface AgentFormModalProps {
  mode: 'add' | 'edit';
  role?: AgentRole;
  agent?: Agent;
  onSubmit: (data: any) => Promise<void>;
  onClose: () => void;
}

export function AgentFormModal({ mode, role, agent, onSubmit, onClose }: AgentFormModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">
            {mode === 'add' ? 'Add New Agent' : 'Edit Agent'}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faXmark} className="w-5 h-5" />
          </button>
        </div>
        
        <div className="p-4">
          <AgentForm
            mode={mode}
            role={role}
            initialData={agent}
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        </div>
      </div>
    </div>
  );
}