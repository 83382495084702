import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Agent, AgentRole } from '../../types/agent';
import { Navbar } from '../components/Navbar';
import { NoticeBar } from '../components/NoticeBar';
import { AgentTable } from '../components/AgentTable';
import { Footer } from '../components/Footer';

const roleMap: Record<string, AgentRole> = {
  'company-head': 'COMPANY_HEAD',
  'admins': 'ADMIN',
  'ss-admins': 'SS_ADMIN',
  'sub-admins': 'SUB_ADMIN',
  'super-agents': 'SUPER_AGENT',
  'master-agents': 'MASTER_AGENT',
};

const roleDisplayNames: Record<AgentRole, string> = {
  'COMPANY_HEAD': 'Company Head',
  'ADMIN': 'Admin',
  'SS_ADMIN': 'SS Admin',
  'SUB_ADMIN': 'Sub Admin',
  'SUPER_AGENT': 'Super Agent',
  'MASTER_AGENT': 'Master Agent',
};

export function AgentListPage() {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop() || '';
  const role = roleMap[currentPath] as AgentRole;

  useEffect(() => {
    const fetchAgents = async () => {
      if (!role) return;
      
      setLoading(true);
      try {
        const agentsRef = collection(db, 'agents');
        const q = query(agentsRef, where('role', '==', role));
        const snapshot = await getDocs(q);
        
        const fetchedAgents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt.toDate(),
        })) as Agent[];
        
        setAgents(fetchedAgents);
      } catch (error) {
        console.error('Error fetching agents:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [role]);

  if (!role) {
    return <div>Invalid role</div>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-900">
      <Navbar />
      <NoticeBar />
      
      {/* Page Header */}
      <div className="bg-black py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold text-center text-yellow-500">{roleDisplayNames[role]}s</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {loading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
            </div>
          ) : (
            <AgentTable agents={agents} />
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}