import { useState } from 'react';
import { Agent } from '../../types/agent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faFlag, faUser, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';

interface ReportModalProps {
  agent: Agent;
  onClose: () => void;
}

export function ReportModal({ agent, onClose }: ReportModalProps) {
  const [message, setMessage] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !whatsappNumber.trim()) return;

    setLoading(true);
    try {
      await addDoc(collection(db, 'reports'), {
        agentId: agent.id,
        agentName: agent.name,
        agentRole: agent.role,
        message,
        whatsappNumber,
        status: 'pending',
        createdAt: serverTimestamp()
      });

      toast.success('Report submitted successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to submit report');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-lg max-w-md w-full border border-yellow-500/20 shadow-xl">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-yellow-500/20">
          <div className="flex items-center space-x-3">
            <div className="h-8 w-8 rounded-lg bg-red-600 flex items-center justify-center">
              <FontAwesomeIcon icon={faFlag} className="text-white" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white">Report Agent</h3>
              <p className="text-sm text-gray-400">Submit an agent report</p>
            </div>
          </div>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </button>
        </div>
        
        {/* Agent Info */}
        <div className="p-4 bg-yellow-500/5 border-b border-yellow-500/20">
          <div className="flex items-center space-x-3">
            <div className="h-10 w-10 rounded-full bg-yellow-500/10 flex items-center justify-center">
              <FontAwesomeIcon icon={faUser} className="text-yellow-500" />
            </div>
            <div>
              <div className="text-white font-medium">{agent.name}</div>
              <div className="flex items-center text-sm text-gray-400 mt-0.5">
                <FontAwesomeIcon icon={faIdCard} className="mr-1.5" />
                <span>{agent.magicId}</span>
              </div>
            </div>
            <div className="ml-auto">
              <span className="px-2.5 py-1 rounded-full text-xs font-medium bg-yellow-500/10 text-yellow-500">
                {agent.role.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}
              </span>
            </div>
          </div>
        </div>
        
        {/* Form */}
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-yellow-500 mb-2">
              Your WhatsApp Number
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon icon={faWhatsapp} className="text-gray-400" />
              </div>
              <input
                type="text"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
                className="w-full pl-10 rounded-lg bg-gray-800 border border-yellow-500/20 text-white placeholder-gray-400 focus:ring-2 focus:ring-yellow-500/20 focus:border-yellow-500/20"
                placeholder="Enter your WhatsApp number"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-yellow-500 mb-2">
              Report Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full rounded-lg bg-gray-800 border border-yellow-500/20 text-white placeholder-gray-400 focus:ring-2 focus:ring-yellow-500/20 focus:border-yellow-500/20"
              rows={4}
              placeholder="Please describe the issue in detail..."
              required
            />
          </div>
          
          <div className="flex justify-end space-x-3 pt-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-300 bg-gray-800 border border-gray-700 rounded-lg hover:bg-gray-700 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? (
                <div className="flex items-center">
                  <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin mr-2"></div>
                  <span>Submitting...</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faFlag} className="mr-2" />
                  <span>Submit Report</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}